import {gsap} from "gsap";

window.Cursor = function () {
  this.cursor = document.querySelector('.cursor');

  this.init = function () {
    this.cursor.classList.remove('cursor--hover');
    const links = document.querySelectorAll('a[href]');
    const iframes = document.querySelectorAll('iframe');

    document.addEventListener('mousemove', this._handleMouseMove.bind(this));

    links.forEach((el) => {
      el.addEventListener('mouseenter', this._handleMouseEnter.bind(this));
      el.addEventListener('mouseleave', this._handleMouseLeave.bind(this));
    });

    iframes.forEach((el) => {
      el.addEventListener('mouseenter', this._handleMouseHiddenEnter.bind(this));
      el.addEventListener('mouseleave', this._handleMouseHiddenLeave.bind(this));
    });
  }

  this._handleMouseMove = function (e) {
    gsap.to(this.cursor, {
      duration: .4,
      x: e.clientX - 33,
      y: e.clientY - 33
    })
  }

  this._handleMouseEnter = function (e) {
    this.cursor.classList.add('cursor--hover');
  }

  this._handleMouseLeave = function (e) {
    this.cursor.classList.remove('cursor--hover');
  }

  this._handleMouseHiddenEnter = function (e) {
    this.cursor.classList.add('cursor--hidden');
  }

  this._handleMouseHiddenLeave = function (e) {
    this.cursor.classList.remove('cursor--hidden');
  }
};

new Cursor().init();
